<template>
  <div class="post-detail">
    <div class="detail-content">
      <div class="post-msg">
        <p class="post-msg-title">{{ content.title }}</p>
        <p class="post-msg-info">
          <span>{{ content.postName }}</span
          >| <span>{{ content.address }}</span
          >| <span>{{ content.type }}</span
          >| <span>{{ content.time }}</span>
        </p>
      </div>
      <div class="post-card" v-for="(item, index) in lists" :key="index">
        <div class="card-title">
          {{ item.title }}
        </div>
        <div class="card-content" v-html="item.content"></div>
      </div>
      <div class="back" @click="back">
        返回列表
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetOfferDetails } from "../../api/index";
export default {
  data() {
    return {
      content: {
        title: "",
        postName: "",
        address: "",
        type: "",
        time: "",
      },
      lists: [
        {
          title: "工作职责",
          content: "",
        },
        {
          title: "任职资格",
          content: "",
        },
        {
          title: "联系方式",
          content: "",
        },
      ],
    };
  },
  methods: {
    init() {
      let id = this.$route.params.id;
      apiGetOfferDetails(id).then((data) => {
        if (data.data.status == 1) {
          this.content = {
            title: data.data.offer.position,
            postName: data.data.offer.area,
            address: data.data.offer.location,
            type: data.data.offer.type === "2" ? "校园招聘" : "社会招聘",
            time: data.data.offer.times,
          };

          this.lists[0].content =  data.data.offer.duties
          this.lists[1].content =  data.data.offer.claim
          this.lists[2].content =  data.data.offer.contact
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less">
.post-detail {
  width: 100%;
  height: 100%;
  padding: 15px 100px;
  .detail-content {
    border: 1px solid #eee;
    padding: 40px 150px;
    .post-msg {
      border-bottom: 1px solid #eee;
      padding: 10px 0 20px;
      .post-msg-title {
        color: #e95506;
        font-size: 40px;
        // font-weight: 600;
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      }
      .post-msg-info {
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        font-size: 24px;
        margin: 25px 0;
        span {
          margin-right: 5px;
          //   margin-left: 10px;
        }
      }
    }
    .post-card {
      padding: 44px 4px 10px 4px;
      .card-title {
        color: #333;
        font-size: 40px;
        margin-bottom: 22px;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      }
      .card-content {
        color: #999;
        font-size: 22px;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        p {
          line-height: 33px;
        }
      }
    }
    .back {
      width: 300px;
      height: 80px;
      background-color: #efefef;
      border-radius: 5px;
      text-align: center;
      color: #333;
      line-height: 80px;
      font-size: 30px;
      cursor: pointer;
      margin-top: 76px;
    }
  }
}
</style>
